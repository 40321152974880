import React, { useState } from 'react';
import { IDocument } from '../../../../Interfaces/IDocument';
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { ILegalTransactionFullObject } from '../../../../Interfaces/ILegalTransaction';
import { ISystem } from '../../../../Interfaces/ISystem';
import { uploadFetch } from '../../../../hooks/useFetch';
import { CustomCircularProgress } from '../../../generic/CustomCircularProgress';
import {
  DataGrid,
  GridColDef,
  GridRowSelectionModel,
  deDE,
} from '@mui/x-data-grid';
import { IConfig } from '../../../../Interfaces/IConfig';
import { RequestFileHelp } from '../../../core/RequestFileHelp';

interface IProps {
  configObject: IConfig;
  legalTransaction: ILegalTransactionFullObject;
  documentArray: IDocument[];
  setIsOpen: Function;
  idDocument?: number;
}

const columns: GridColDef[] = [
  { field: 'FileName', headerName: 'Dateiname', flex: 1 },
];

export const LegalRequirementRequestFiles: React.FC<IProps> = ({
  configObject,
  legalTransaction,
  documentArray,
  setIsOpen,
  idDocument,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [wasSuccessfully, setWasSuccessfully] = useState(true);

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>(idDocument ? [idDocument] : []);

  const handleRequestFiles = () => {
    localStorage.setItem('requested_data', 'true');
    const uploadSync: ISystem = {
      idContact: Number(localStorage.getItem('idContact')),
      idLegalTransaction: legalTransaction.idLegalTransaction,
      SyncType: 'RequestFiles',
      Data: rowSelectionModel,
    };
    uploadFetch(
      '/system',
      true,
      uploadSync,
      () => setCurrentPage(1),
      setWasSuccessfully,
      () => {},
      setIsLoading
    );
  };

  if (isLoading) {
    return <CustomCircularProgress />;
  } else if (currentPage === 0) {
    return (
      <>
        <DialogTitle>Dokumente anfragen</DialogTitle>
        <DialogContent>
          Bitte wählen Sie die Dokumente aus, die Sie{' '}
          <RequestFileHelp configObject={configObject} /> möchten.
          <DataGrid
            sx={{ mt: 2 }}
            autoHeight
            checkboxSelection
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            rows={documentArray}
            onRowSelectionModelChange={newRowSelectionModel => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            columns={columns}
            getRowId={row => row.idDocument}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setIsOpen(false)}>
            Abbruch
          </Button>
          <Button
            variant='contained'
            onClick={handleRequestFiles}
            disabled={rowSelectionModel.length === 0}
          >
            Anfragen
          </Button>
        </DialogActions>
      </>
    );
  } else if (currentPage === 1 && wasSuccessfully) {
    return (
      <>
        <DialogTitle>Dokumente anfragen</DialogTitle>
        <DialogContent>
          <Alert severity='success'>
            Die Dokumente wurden angefragt!
            <br />
            Sie erhalten innerhalb der nächsten{' '}
            <b>5 Minuten den Download-Link</b> zu Ihren Dokumenten{' '}
            <b>per E-Mail</b>.
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={() => setIsOpen(false)}>
            Schließen
          </Button>
        </DialogActions>
      </>
    );
  } else if (currentPage === 1 && !wasSuccessfully) {
    return (
      <>
        <DialogTitle>Dokumente anfragen</DialogTitle>
        <DialogContent>
          <Alert severity='error'>
            Es ist ein Fehler aufgetreten!
            <br /> Die Fehlerinformationen wurden an die{' '}
            <a href='https://www.lextorbyte.de/' target='_blank'>
              LeXtorByte UG (haftungsbeschränkt)
            </a>{' '}
            übermittelt.
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={() => setIsOpen(false)}>
            Schließen
          </Button>
        </DialogActions>
      </>
    );
  } else {
    return <>Fehler!</>;
  }
};
