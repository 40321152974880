import { Box, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactPasswordChecklist from 'react-password-checklist';

interface IProps {
  passwordIsValid?: boolean;
  setPasswordIsValid?: Function;
  setNewPassword?: Function;
}

export const PasswordResetNew: React.FC<IProps> = props => {
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordAgain, setNewPasswordAgain] = useState('');

  useEffect(() => {
    //Runs on the first render
    //And any time any dependency value changes
    if (props.setPasswordIsValid && props.setNewPassword) {
      if (
        checkPasswordValidity(newPassword) &&
        checkPasswordValidity(newPasswordAgain)
      ) {
        props.setPasswordIsValid(true);
        props.setNewPassword(newPassword);
      } else {
        props.setPasswordIsValid(false);
      }
    }
  }, [newPassword, newPasswordAgain]);

  const checkPasswordValidity = (
    value: string,
    enablePwCheck: boolean = true
  ) => {
    const nonWhiteSpace = /^\S*$/;
    if (!nonWhiteSpace.test(value)) {
      return false;
    }

    const containsUppercase = /^(?=.*[A-Z]).*$/;
    if (!containsUppercase.test(value)) {
      return false;
    }

    const containsLowercase = /^(?=.*[a-z]).*$/;
    if (!containsLowercase.test(value)) {
      return false;
    }

    const containsNumber = /^(?=.*[0-9]).*$/;
    if (!containsNumber.test(value)) {
      return false;
    }

    const containsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
    if (!containsSymbol.test(value)) {
      return false;
    }

    const validLength = /^.{8,32}$/;
    if (!validLength.test(value)) {
      return false;
    }
    if (enablePwCheck && newPassword != newPasswordAgain) {
      return false;
    }
    return true;
  };

  return (
    <>
      <Grid item xs={3} style={{ minWidth: 300 }}>
        <TextField
          label='Neues Passwort'
          type='password'
          value={newPassword}
          fullWidth
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setNewPassword(String(event.target.value))
          }
          required
        />
      </Grid>
      <Box sx={{ mt: 3 }} />
      <Grid item xs={3} style={{ minWidth: 300 }}>
        <TextField
          label='Neues Passwort wiederholen'
          type='password'
          value={newPasswordAgain}
          fullWidth
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setNewPasswordAgain(String(event.target.value))
          }
          required
        />
      </Grid>
      <Box sx={{ mt: 3 }} />
      <ReactPasswordChecklist
        rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
        minLength={12}
        value={newPassword}
        valueAgain={newPasswordAgain}
        onChange={() => {}}
        messages={{
          minLength: 'Das Passwort besteht aus mindestens 12 Zeichen.',
          specialChar: 'Das Passwort beinhaltet ein Sonderzeichen.',
          number: 'Das Passwort beinhaltet eine Ziffern.',
          capital: 'Das Passwort beinhaltet mindestens einen Großbuchstaben.',
          match: 'Die Passwörter stimmen überein.',
        }}
      />
    </>
  );
};
