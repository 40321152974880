import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ILegalRequirement } from '../../../../Interfaces/ILegalRequirement';
import { IConfig } from '../../../../Interfaces/IConfig';
import { ILegalTransactionFullObject } from '../../../../Interfaces/ILegalTransaction';
import { ILegalPhaseFullObject } from '../../../../Interfaces/ILegalPhase';
import { Download, UploadFile } from '@mui/icons-material';
import { DocumentOverview } from '../../Document/DocumentOverview';
import { IDocument } from '../../../../Interfaces/IDocument';

interface IProps {
  configObject: IConfig;
  legalTransaction: ILegalTransactionFullObject;
  legalPhaseFullObjectArray: ILegalPhaseFullObject[];
}

export const LegalPhaseDocumentView: React.FC<IProps> = ({
  configObject,
  legalTransaction,
  legalPhaseFullObjectArray,
}) => {
  const [currentRequirements, setCurrentRequirements] = useState<
    ILegalRequirement[]
  >([]);
  const [currentReqDoc, setCurrentReqDoc] = useState<ILegalRequirement | null>(
    null
  );
  const [documentArrayClient, setDocumentArrayClient] = useState<IDocument[]>(
    []
  );

  const handleOpenEmployees = (localReq: ILegalRequirement) => {
    setCurrentReqDoc(localReq);
  };

  const handleOpenClient = (localReq: ILegalRequirement) => {
    setCurrentReqDoc(localReq);
    setDocumentArrayClient(
      localReq.DocumentArrayClient === undefined
        ? []
        : localReq.DocumentArrayClient
    );
  };

  useEffect(() => {
    const tmpArray: ILegalRequirement[] = [];

    legalPhaseFullObjectArray.map(currentPhase =>
      currentPhase.LegalRequirementArray.map(currentReq => {
        if (
          Boolean(currentReq.canUploadFilesClient) ||
          Boolean(currentReq.canUploadFilesEmployees)
        ) {
          tmpArray.push(currentReq);
        }
      })
    );

    setCurrentRequirements(tmpArray);
  }, [legalPhaseFullObjectArray]);

  return (
    <>
      <Dialog
        open={!!currentReqDoc}
        onClose={() => setCurrentReqDoc(null)}
        maxWidth='lg'
        fullWidth
      >
        <DialogContent>
          <DocumentOverview
            key={`DocumentOverview-client-${
              currentReqDoc?.idLegalRequirement || -1
            }`}
            configObject={configObject}
            legalTransactionObject={legalTransaction}
            idLegalRequirement={currentReqDoc?.idLegalRequirement || -1}
            documentArray={documentArrayClient}
            setDocumentArray={setDocumentArrayClient}
            allowRemove={false}
            allowUpload
            title='Ihre Daten'
            showReadPermission
          />
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={() => setCurrentReqDoc(null)}>
            Okay
          </Button>
        </DialogActions>
      </Dialog>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Maßnahme</TableCell>
            <TableCell>Bereitgestelle Dokumente</TableCell>
            <TableCell>Hochgeladene Dokumente</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentRequirements.length === 0 && (
            <TableRow>
              <TableCell colSpan={3}>
                <i>Derzeitig sind keine Einträge verfügbar.</i>
              </TableCell>
            </TableRow>
          )}
          {currentRequirements.map(requirement => (
            <TableRow
              key={`currentReq-documentview-${requirement.idLegalRequirement}`}
            >
              <TableCell>{requirement.LegalRequirement}</TableCell>
              <TableCell>
                {requirement.DocumentArrayEmployees?.map(
                  document => document.FileName
                ).join(',\n')}
                {requirement.DocumentArrayEmployees &&
                  requirement.DocumentArrayEmployees?.length > 0 &&
                  requirement.canUploadFilesEmployees == true && (
                    <IconButton
                      title='Runterladen von bereitgestellten Dateien'
                      size='small'
                      onClick={() => handleOpenEmployees(requirement)}
                    >
                      <Download />
                    </IconButton>
                  )}
              </TableCell>
              <TableCell>
                {requirement.DocumentArrayClient?.map(
                  document => document.FileName
                ).join(', ')}
                {requirement.canUploadFilesClient && (
                  <IconButton
                    title='Hochladen und runterladen von eigenen Dateien'
                    size='small'
                    onClick={() => handleOpenClient(requirement)}
                  >
                    <UploadFile />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
