import React from 'react';
import { IDocument } from '../../../Interfaces/IDocument';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Download } from '@mui/icons-material';
import { useDocuments } from '../../../hooks/data';

const base64Download = (localDocumentObject: IDocument) => {
  if (localDocumentObject.Data !== undefined) {
    const byteCharacters = atob(localDocumentObject.Data);
    // Each character's code point (charCode) will be the value of the byte.
    // We can create an array of byte values by applying this using the .charCodeAt method for each character in the string.
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    //You can convert this array of byte values into a real typed byte array by passing it to the Uint8Array constructor.
    const byteArray = new Uint8Array(byteNumbers);
    // This in turn can be converted to a BLOB by wrapping it in an array and passing it to the Blob constructor.
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    // Erstelle Link zum BLOB
    const blobUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = localDocumentObject.FileName;
    a.click();
  }
};

export const DownloadArea: React.FC = () => {
  const isDesktop = useMediaQuery('(min-width:600px)');

  const { documents, isLoadingDocuments } = useDocuments();

  if (isLoadingDocuments) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!documents || documents.length === 0) {
    return (
      <Box sx={{ mt: 2, mb: 10 }}>
        <Typography variant='h4'>Meine Dokumente</Typography>
        <Alert severity='info'>
          Derzeit sind keine Dokumente verfügbar.
          <br />
          Sofern Sie Dokumente angefragt haben, werden diese hier aufgelistet
          und können heruntergeladen werden. Bitte haben Sie einen Moment
          Geduld, während die Bereitstellung der Dokumente geprüft wird. Dieser
          Vorgang kann bis zu fünf Minuten dauern.
        </Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 2, mb: 10 }}>
      <Typography variant='h4'>Meine Dokumente</Typography>
      <Alert severity='info' sx={{ mt: 2 }}>
        Um Mitternacht werden alle angefragten Dokumente von diesem Server
        gelöscht.
      </Alert>

      <Table size='small' sx={{ mt: 2 }}>
        <TableHead>
          <TableRow>
            <TableCell>Dateiname</TableCell>
            <TableCell sx={{ width: 20 }}>Aktionen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.map(document => (
            <TableRow
              sx={{ cursor: 'pointer' }}
              onClick={() => base64Download(document)}
            >
              <TableCell>{document.FileName}</TableCell>
              <TableCell>
                {isDesktop ? (
                  <Button variant='contained'>Download</Button>
                ) : (
                  <IconButton>
                    <Download />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
