import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from '@mui/material';
import { Download, UploadFile } from '@mui/icons-material';
import { DocumentOverview } from '../../Document/DocumentOverview';
import { ILegalRequirement } from '../../../../Interfaces/ILegalRequirement';
import { IDocument } from '../../../../Interfaces/IDocument';
import { LegalRequirementRequestFiles } from './LegalRequirementRequestFiles';
import { ILegalTransactionFullObject } from '../../../../Interfaces/ILegalTransaction';
import { LegalRequirementUploadFiles } from './LegalRequirementUploadFiles';
import { IConfig } from '../../../../Interfaces/IConfig';

interface IProps {
  configObject: IConfig;
  legalTransactionObject: ILegalTransactionFullObject;
  legalRequirementObject: ILegalRequirement;
  isNotDisabled: boolean;
}

export const LegalRequirementDocuments: React.FC<IProps> = ({
  configObject,
  legalTransactionObject,
  legalRequirementObject,
  isNotDisabled,
}) => {
  const [documentArrayClient, setDocumentArrayClient] = useState<IDocument[]>(
    legalRequirementObject.DocumentArrayClient || []
  );
  const [documentArrayEmployees, setDocumentArrayEmployees] = useState<
    IDocument[]
  >(legalRequirementObject.DocumentArrayEmployees || []);

  const [isOpenClient, setIsOpenClient] = useState(false);
  const [isOpenEmployees, setIsOpenEmployees] = useState(false);
  const [isOpenUpload, setIsOpenUpload] = useState(false);

  const handleCloseClient = () => {
    const foundItem = documentArrayClient.find(x => x.idDocument < 0);

    if (!foundItem) {
      setIsOpenClient(false);
    } else {
      setIsOpenClient(false);
      setIsOpenUpload(true);
    }
  };

  const hanldeOpenEmployees = () => {
    setDocumentArrayEmployees(
      legalRequirementObject.DocumentArrayEmployees || []
    );
    setIsOpenEmployees(true);
  };

  return (
    <>
      <LegalRequirementUploadFiles
        //idLegalRequirement={legalRequirementObject.idLegalRequirement}
        //idContact={Number(localStorage.getItem('idContact'))}
        idLegalTransaction={legalTransactionObject.idLegalTransaction}
        documentArrayClient={documentArrayClient}
        setDocumentArrayClient={setDocumentArrayClient}
        isOpen={isOpenUpload}
        setIsOpen={setIsOpenUpload}
      />

      <Dialog
        open={isOpenClient}
        onClose={handleCloseClient}
        maxWidth='lg'
        fullWidth
      >
        <DialogContent>
          <DocumentOverview
            key={`DocumentOverview-client`}
            configObject={configObject}
            legalTransactionObject={legalTransactionObject}
            idLegalRequirement={legalRequirementObject.idLegalRequirement}
            documentArray={documentArrayClient}
            setDocumentArray={setDocumentArrayClient}
            allowRemove={false}
            allowUpload
            title='Ihre Daten'
            showReadPermission
          />
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleCloseClient}>
            Okay
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isOpenEmployees}
        onClose={() => setIsOpenEmployees(false)}
        maxWidth='lg'
        fullWidth
      >
        <LegalRequirementRequestFiles
          configObject={configObject}
          legalTransaction={legalTransactionObject}
          documentArray={documentArrayEmployees}
          setIsOpen={setIsOpenEmployees}
        />
      </Dialog>

      {legalRequirementObject.canUploadFilesClient == true && (
        <IconButton
          title='Hochladen und runterladen von eigenen Dateien'
          size='small'
          onClick={() => {
            setIsOpenClient(true);
          }}
          disabled={!isNotDisabled}
        >
          <UploadFile />
        </IconButton>
      )}
      {documentArrayEmployees.length > 0 &&
        legalRequirementObject.canUploadFilesEmployees == true && (
          <IconButton
            title='Runterladen von bereitgestellten Dateien'
            size='small'
            onClick={hanldeOpenEmployees}
            disabled={!isNotDisabled}
          >
            <Download />
          </IconButton>
        )}
    </>
  );
};
