import { useMutation, useQuery } from '@tanstack/react-query';
import { customFetcher } from './useFetch';
import type { ILegalTransactionFullObject } from '../Interfaces/ILegalTransaction';
import type { ILegalTransactionProgress } from '../Interfaces/ILegalTransactionProgress';
import type { IDocument } from '../Interfaces/IDocument';
import type { IGender } from '../Interfaces/IGender';
import type { IAcademicTitle } from '../Interfaces/IAcademicTitle';
import type { ICountry } from '../Interfaces/ICountry';
import type { IMIMEType } from '../Interfaces/IMIMEType';
import type { IContact } from '../Interfaces/IContact';
import type { IDraftMessage } from '../Interfaces/IDraftMessage';
import type { IContactInvite } from '../Interfaces/IContactInvite';
import type { ISystem } from '../Interfaces/ISystem';
import type { IConfig } from '../Interfaces/IConfig';
import type {
  ILegalTransactionMetadata,
  ILegalTransactionsMetadata,
} from '../Interfaces/ILegalTransactionMetadata';

export const useLegalTransactions = () => {
  const {
    data: legalTransactions,
    isLoading: isLoadingLegalTransactions,
    refetch: refetchLegalTransactions,
  } = useQuery<ILegalTransactionFullObject[]>({
    queryKey: ['legalTransactions'],
    queryFn: () => customFetcher({ endpoint: '/legaltransaction' }),
  });

  return {
    legalTransactions,
    isLoadingLegalTransactions,
    refetchLegalTransactions,
  };
};

export const useLegalTransactionProgresses = () => {
  const {
    data: legalTransactionProgresses,
    isLoading: isLoadingLegalTransactionsProgresses,
    refetch: refetchLegalTransactionsProgresses,
  } = useQuery<ILegalTransactionProgress[]>({
    queryKey: ['legalTransactionsProgresses'],
    queryFn: () => customFetcher({ endpoint: '/legaltransactionprogress' }),
  });

  return {
    legalTransactionProgresses,
    isLoadingLegalTransactionsProgresses,
    refetchLegalTransactionsProgresses,
  };
};

export const useDocuments = () => {
  const { data: documents, isLoading: isLoadingDocuments } = useQuery<
    IDocument[]
  >({
    queryKey: ['documents'],
    queryFn: () => customFetcher({ endpoint: '/document' }),
  });

  return {
    documents,
    isLoadingDocuments,
  };
};

export const useGenders = () => {
  const { data: genders, isLoading: isLoadingGenders } = useQuery<IGender[]>({
    queryKey: ['genders'],
    queryFn: () => customFetcher({ endpoint: '/gender' }),
  });

  return {
    genders,
    isLoadingGenders,
  };
};

export const useAcademicTitles = () => {
  const { data: academicTitles, isLoading: isLoadingAcademicTitles } = useQuery<
    IAcademicTitle[]
  >({
    queryKey: ['academicTitles'],
    queryFn: () => customFetcher({ endpoint: '/academictitle' }),
  });

  return {
    academicTitles,
    isLoadingAcademicTitles,
  };
};

export const useCountries = () => {
  const { data: countries, isLoading: isLoadingCountries } = useQuery<
    ICountry[]
  >({
    queryKey: ['countries'],
    queryFn: () => customFetcher({ endpoint: '/country' }),
  });

  return {
    countries,
    isLoadingCountries,
  };
};

export const useMimeTypes = () => {
  const { data: mimeTypes, isLoading: isLoadingMimeTypes } = useQuery<
    IMIMEType[]
  >({
    queryKey: ['mimeTypes'],
    queryFn: () => customFetcher({ endpoint: '/mimetype' }),
  });

  return {
    mimeTypes,
    isLoadingMimeTypes,
  };
};

export const useContact = () => {
  const { data: contact, isLoading: isLoadingContact } = useQuery<IContact>({
    queryKey: ['contact'],
    queryFn: () => customFetcher({ endpoint: '/contact' }),
  });

  return {
    contact,
    isLoadingContact,
  };
};

export const useUpdateContact = () => {
  const { mutateAsync: updateContact, isPending: isPendingUpdateContact } =
    useMutation({
      mutationFn: (contact: IContact) =>
        customFetcher({ endpoint: '/contact', method: 'PUT', body: contact }),
    });

  return {
    updateContact,
    isPendingUpdateContact,
  };
};

export const useCreateDraftMessage = () => {
  const {
    mutateAsync: createDraftMessage,
    isPending: isPendingCreateDraftMessage,
  } = useMutation({
    mutationFn: (draftMessage: IDraftMessage) =>
      customFetcher({
        endpoint: '/draftmessage',
        method: 'POST',
        body: draftMessage,
      }),
  });

  return {
    createDraftMessage,
    isPendingCreateDraftMessage,
  };
};

export const useCreateContactInvite = () => {
  const {
    mutateAsync: createContactInvite,
    isPending: isPendingCreateContactInvite,
  } = useMutation({
    mutationFn: (contactInvite: IContactInvite) =>
      customFetcher({
        endpoint: '/contactinvite/invite',
        method: 'POST',
        body: contactInvite,
      }),
  });

  return {
    createContactInvite,
    isPendingCreateContactInvite,
  };
};

export const useRequestNewPassword = () => {
  const {
    mutateAsync: requestNewPassword,
    isPending: isPendingRequestNewPassword,
  } = useMutation({
    mutationFn: (email: string) =>
      customFetcher({
        endpoint: '/contact/requestNewPassword',
        method: 'POST',
        body: { Email: email },
      }),
  });

  return {
    requestNewPassword,
    isPendingRequestNewPassword,
  };
};

export const useValidatePassword = () => {
  const {
    mutateAsync: validatePassword,
    isPending: isPendingValidatePassword,
  } = useMutation({
    mutationFn: (password: string) =>
      customFetcher({
        endpoint: '/contact/validatePassword',
        method: 'POST',
        body: { Password: password },
      }),
  });

  return {
    validatePassword,
    isPendingValidatePassword,
  };
};

export const useCreateSystem = () => {
  const { mutateAsync: createSystem, isPending: isPendingCreateSystem } =
    useMutation({
      mutationFn: (system: ISystem) =>
        customFetcher({
          endpoint: '/system',
          method: 'POST',
          body: system,
        }),
    });

  return {
    createSystem,
    isPendingCreateSystem,
  };
};

export const useConfig = () => {
  const { data: config, isLoading: isLoadingConfig } = useQuery<IConfig>({
    queryKey: ['config'],
    queryFn: () => customFetcher({ endpoint: '/config' }),
  });

  return {
    config,
    isLoadingConfig,
  };
};

export const useMetadata = () => {
  const {
    data: metadata,
    isLoading: isLoadingMetadata,
    refetch: refetchMetadata,
  } = useQuery<ILegalTransactionsMetadata>({
    queryKey: ['metadata'],
    queryFn: () => customFetcher({ endpoint: '/legaltransaction/getMetadata' }),
  });

  return {
    metadata,
    isLoadingMetadata,
    refetchMetadata,
  };
};

export const useUpdateMetadata = () => {
  const { mutateAsync: updateMetadata, isPending: isPendingUpdateMetadata } =
    useMutation({
      mutationFn: (metadata: ILegalTransactionMetadata) =>
        customFetcher({
          endpoint: '/legaltransaction/updateMetadata',
          method: 'PUT',
          body: metadata,
        }),
    });

  return {
    updateMetadata,
    isPendingUpdateMetadata,
  };
};
