import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { ViewDraftMessage } from './DraftMessage/ViewDraftMessage';
import { NewDraftMessage } from './DraftMessage/NewDraftMessage';
import { IDocument } from '../../../Interfaces/IDocument';
import { IDraftMessageFullObject } from '../../../Interfaces/IDraftMessage';
import { ILegalTransactionFullObject } from '../../../Interfaces/ILegalTransaction';
import { DraftDocumentOverview } from './DraftMessage/DraftDocumentOverview';
import { IConfig } from '../../../Interfaces/IConfig';

interface IProps {
  configObject: IConfig;
  legalTransactionObject: ILegalTransactionFullObject;
  draftArray: IDocument[];
  draftMessageArray: IDraftMessageFullObject[];
  setDraftMessageArray: Function;
}

export const LegalTransactionDraftOverview: React.FC<IProps> = ({
  configObject,
  legalTransactionObject,
  draftArray,
  draftMessageArray,
  setDraftMessageArray,
}) => {
  const [idDraftMessageParent, setIdDraftMessageParent] = useState<
    number | null
  >(null);

  return (
    <>
      <DraftDocumentOverview
        configObject={configObject}
        legalTransactionObject={legalTransactionObject}
        draftArray={draftArray}
      />

      <Typography variant='h5' sx={{ mb: 3 }}>
        Kommentare
        <NewDraftMessage
          configObject={configObject}
          legalTransactionObject={legalTransactionObject}
          draftMessageArray={draftMessageArray}
          setDraftMessageArray={setDraftMessageArray}
          idDraftMessageParent={idDraftMessageParent}
          setIdDraftMessageParent={setIdDraftMessageParent}
        />
      </Typography>
      <Grid container spacing={2}>
        {draftMessageArray.length === 0 && (
          <Grid item xs={12}>
            <>--- Keine Kommentare vorhanden ---</>
          </Grid>
        )}

        {draftMessageArray.map(draftMessage => (
          <Grid item xs={12}>
            <ViewDraftMessage
              key={draftMessage.idDraftMessage}
              configObject={configObject}
              legalTransactionObject={legalTransactionObject}
              draftMessageArray={draftMessageArray}
              setDraftMessageArray={setDraftMessageArray}
              draftMessageObject={draftMessage}
              setIdDraftMessageParent={setIdDraftMessageParent}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
