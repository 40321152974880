import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import { LegalRequirementDocuments } from './LegalRequirementDocuments';
import {
  ILegalRequirement,
  ILegalRequirementDeadline,
} from '../../../../Interfaces/ILegalRequirement';
import { ILegalTransactionFullObject } from '../../../../Interfaces/ILegalTransaction';
import { LegalInfoText } from './LegalInfoText';
import { IConfig } from '../../../../Interfaces/IConfig';
import { HourglassFull, Done, PriorityHigh } from '@mui/icons-material';

interface IProps {
  configObject: IConfig;
  legalTransaction: ILegalTransactionFullObject;
  legalRequirement: ILegalRequirement;
  legalRequirementArray: ILegalRequirement[];
  setLegalRequirementArray: Function;
  isNotDisabled: boolean;
  startDeadlineDate: string | null;
}

export const LegalRequirementRow: React.FC<IProps> = ({
  configObject,
  legalTransaction,
  legalRequirement,
  legalRequirementArray,
  setLegalRequirementArray,
  isNotDisabled,
  startDeadlineDate,
}) => {
  useEffect(() => {
    setLegalRequirementArray([
      ...legalRequirementArray.map(requirement =>
        requirement.idLegalRequirement === legalRequirement.idLegalRequirement
          ? legalRequirement
          : requirement
      ),
    ]);
  }, [legalRequirement]);

  const addDaysToDate = (date: Date, days: number): Date => {
    const millisecondsPerDay = 1000 * 60 * 60 * 24; // Anzahl der Millisekunden in einem Tag
    const result = new Date(date.getTime() + days * millisecondsPerDay);
    return result;
  };

  const getEarliestTriggerDate = useCallback(
    (deadlineArray: ILegalRequirementDeadline[]) => {
      const highestWeighting = deadlineArray
        .filter(deadline => Boolean(deadline.DeadlineTriggeredAt))
        .reduce((maxWeighting, current) => {
          return current.Weighting > maxWeighting
            ? current.Weighting
            : maxWeighting;
        }, 0);

      const earliestTriggerDate = deadlineArray
        .filter(deadline => {
          return Boolean(deadline.DeadlineTriggeredAt);
        })
        .filter(x => x.Weighting == highestWeighting)
        .reduce((earliestTriggerDate, current) => {
          return new Date(current.DeadlineTriggeredAt!) < earliestTriggerDate
            ? new Date(current.DeadlineTriggeredAt!)
            : earliestTriggerDate;
        }, new Date());

      return earliestTriggerDate;
    },
    []
  );

  const getMaxExceededDays = useCallback(() => {
    const { DeadlineArray, initialProcessingTime } = legalRequirement;

    if (DeadlineArray && DeadlineArray.length > 0) {
      const earliestTriggerDate = getEarliestTriggerDate(DeadlineArray);

      const differenceInTime =
        new Date().getTime() -
        addDaysToDate(
          earliestTriggerDate,
          legalRequirement.initialProcessingTime || 0
        ).getTime();
      return Math.floor(differenceInTime / (1000 * 3600 * 24));
    } else if (startDeadlineDate) {
      // Prüfe, ob das startDate ein gültiges Datum ist
      const startDateObject = new Date(startDeadlineDate);
      if (isNaN(startDateObject.getTime())) {
        console.error('Invalid startDate:', startDeadlineDate);
        return legalRequirement.initialProcessingTime || 0;
      }

      // Hier wird initialProcessingTime korrekt zum Datum addiert
      const adjustedStartDate = addDaysToDate(
        startDateObject,
        initialProcessingTime || 0
      );

      const currentDate = new Date();
      const differenceInTime =
        currentDate.getTime() - adjustedStartDate.getTime();
      return Math.floor(differenceInTime / (1000 * 3600 * 24)); // Kein Math.max, damit negative Werte berücksichtigt werden
    }

    return Math.floor(legalRequirement.initialProcessingTime || 10);
  }, [getEarliestTriggerDate, legalRequirement, startDeadlineDate]);

  const hasAtLeastOneDeadlineTriggered = useCallback(() => {
    const { DeadlineArray } = legalRequirement;
    if (DeadlineArray && DeadlineArray.length > 0) {
      return DeadlineArray.some(deadline =>
        Boolean(deadline.DeadlineTriggeredAt)
      );
    }
    if (startDeadlineDate !== null) {
      if (new Date(startDeadlineDate) <= new Date()) {
        return true;
      }
    }
    return false;
  }, [legalRequirement, startDeadlineDate]);

  const [exceededDays, setExceededDays] = useState(getMaxExceededDays());
  const [atLeastOneDeadlineTriggered, setAtLeastOneDeadlineTriggered] =
    useState(hasAtLeastOneDeadlineTriggered());

  useEffect(() => {
    setExceededDays(getMaxExceededDays());
    setAtLeastOneDeadlineTriggered(hasAtLeastOneDeadlineTriggered());
  }, [
    getEarliestTriggerDate,
    getMaxExceededDays,
    hasAtLeastOneDeadlineTriggered,
    legalRequirement,
    startDeadlineDate,
  ]);

  return (
    <>
      <Grid item xs={2} sm={3} lg={1}></Grid>

      <Grid item xs={10} sm={9} lg={11} direction={'row'}>
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ width: '100%' }}
          width={'100%'}
        >
          <Alert
            variant={isNotDisabled ? 'standard' : 'outlined'}
            severity={
              legalRequirement.idLegalRequirementState === 40
                ? 'success'
                : legalRequirement.enableError &&
                  legalRequirement.idLegalRequirementState === 30
                ? 'error'
                : 'info'
            }
            action={
              <>
                <LegalInfoText currentObject={legalRequirement} />
                <Typography variant='caption'>
                  {legalRequirement.idLegalRequirementState < 30
                    ? 'Offen'
                    : legalRequirement.LegalRequirementState}
                </Typography>
              </>
            }
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <Box width={'100%'} display={'flex'} alignItems={'center'}>
              {legalRequirement.LegalRequirement}

              {atLeastOneDeadlineTriggered &&
                legalRequirement.idLegalRequirementState < 15 && (
                  <Tooltip
                    title={`${
                      legalRequirement.Court
                        ? `Die Maßnahme liegt bei ${legalRequirement.CourtRole} ${legalRequirement.Court}.`
                        : ''
                    }   
                    ${
                      exceededDays < -1
                        ? Math.abs(exceededDays) !== 1
                          ? `Die vorraussichtliche Fertigstellung erfolgt in ${Math.abs(
                              exceededDays
                            )} Tagen`
                          : `Die vorraussichtliche Fertigstellung erfolgt in ${Math.abs(
                              exceededDays
                            )} Tag`
                        : 'Überfällig. Die Bearbeitung wird vom Notarbüro angemahnt. Eine Rückmeldung ins Notarbüro ist nicht erforderlich.'
                    }`}
                  >
                    <Box
                      display='flex'
                      alignItems='center'
                      sx={{ width: 150 }}
                      ml={4}
                      mr={2}
                    >
                      {exceededDays <= 0 ? (
                        <LinearProgress
                          variant='determinate'
                          color='success'
                          value={
                            (Math.abs(
                              legalRequirement.initialProcessingTime +
                                exceededDays
                            ) /
                              legalRequirement.initialProcessingTime) *
                            100
                          }
                          sx={{ flexGrow: 1, marginRight: 2 }} // Responsive width with flexGrow
                        />
                      ) : (
                        <LinearProgress
                          variant='determinate'
                          color='warning'
                          value={100}
                          sx={{ flexGrow: 1, marginRight: 2 }} // Responsive width with flexGrow
                        />
                      )}
                    </Box>
                  </Tooltip>
                )}
              {!atLeastOneDeadlineTriggered &&
                legalRequirement.idLegalRequirementState < 15 && (
                  <Tooltip title='Die Bearbeitung der Maßnahme hat laut Plan noch nicht begonnen.'>
                    <span>
                      <IconButton disabled>
                        <HourglassFull color='disabled'></HourglassFull>
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              {(legalRequirement.idLegalRequirementState === 15 ||
                legalRequirement.idLegalRequirementState === 20) && (
                <Tooltip
                  title={
                    'Die Bearbeitung der Maßnahme ist abgeschlossen. Sie befindet sich aktuell in der Überprüfung.'
                  }
                >
                  <span>
                    <IconButton disabled>
                      <Done color='info'></Done>
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              {legalRequirement.idLegalRequirementState === 30 && (
                <Tooltip
                  title={
                    'Beim Bearbeiten der Maßnahme ist ein Fehler aufgetreten.'
                  }
                >
                  <span>
                    <IconButton>
                      <PriorityHigh color='error'></PriorityHigh>
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              <LegalRequirementDocuments
                configObject={configObject}
                legalTransactionObject={legalTransaction}
                legalRequirementObject={legalRequirement}
                isNotDisabled={isNotDisabled}
              />
              {legalRequirement.sendMail && (
                <Typography variant='caption'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(E-Mail wird versendet)
                </Typography>
              )}
            </Box>
          </Alert>
        </Box>
      </Grid>
    </>
  );
};
