import React, { useState } from 'react';
import { IConfig } from '../../Interfaces/IConfig';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { PasswordResetNew } from '../core/PasswordResetNew';
import md5 from 'md5';
import { uploadFetch, useFetch } from '../../hooks/useFetch';
import { IContact } from '../../Interfaces/IContact';
import { Logout } from '@mui/icons-material';
import { useAcademicTitles, useGenders } from '../../hooks/data';
import { Loader } from '../generic/Loader';
interface IProps {
  configObject: IConfig;
}

export const LoginData: React.FC<IProps> = ({ configObject }) => {
  const { genders, isLoadingGenders } = useGenders();
  const { academicTitles, isLoadingAcademicTitles } = useAcademicTitles();
  const [clientEdit, setClientEdit, wasSuccessfully] =
    useFetch<IContact>('/contact');

  const [newPassword, setNewPassword] = useState<string>('');
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [isLoadingSaved, setIsLoadingSaved] = useState(false);
  const [wasSuccessfullySaved, setWasSuccessfullySaved] = useState(true);
  const [isDsgvoOpen, setIsDsgvoOpen] = useState(false);
  const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] =
    useState(false);

  const handleDsgvoOpen = () => {
    setIsDsgvoOpen(true);
  };

  const handleDsgvoClose = () => {
    setIsDsgvoOpen(false);
  };

  const handleAfterSave = () => {
    localStorage.setItem('passwordResetRequired', 'false');
    window.location.reload();
  };

  const handleSubmitPasswordChange = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (passwordIsValid) {
      const uplaodObject = {
        ...clientEdit,
        Password: md5(newPassword),
      } as IContact;

      if ('allowDataChange' in uplaodObject) {
        uplaodObject.allowDataChange = false;
      }
      uploadFetch(
        '/contact',
        false,
        uplaodObject,
        handleAfterSave,
        setWasSuccessfullySaved,
        undefined,
        setIsLoadingSaved
      );
    }
  };

  if (
    isLoadingSaved ||
    !clientEdit ||
    isLoadingGenders ||
    isLoadingAcademicTitles
  ) {
    return <Loader />;
  }

  if (
    !wasSuccessfully ||
    !wasSuccessfullySaved ||
    !genders ||
    !academicTitles
  ) {
    return <Alert severity='error'>Es ist ein Fehler aufgetreten!</Alert>;
  }

  return (
    <>
      <Dialog open={isDsgvoOpen} onClose={handleDsgvoClose}>
        <DialogTitle id='alert-dialog-title'>
          {'Datenschutzerklärung'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <div dangerouslySetInnerHTML={{ __html: configObject.gdpr_html }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDsgvoClose}>Zurück</Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ m: 2 }}>
        <form onSubmit={handleSubmitPasswordChange} autoComplete='on'>
          <Button
            variant='contained'
            sx={{ float: 'right' }}
            onClick={() => {
              localStorage.removeItem('SessionToken');
              window.location.reload();
            }}
          >
            Abmelden <Logout sx={{ ml: 1 }} />
          </Button>
          <Grid
            container
            spacing={0}
            direction='column'
            alignItems='center'
            justifyContent='center'
          >
            <img
              width={250}
              src={`data:${configObject.logo_mime};base64,${configObject.logo}`}
            />
            <Box sx={{ mt: 4 }} />
            <Grid item style={{ width: 300 }}>
              <Box sx={{ textAlign: 'center' }}>
                <b>Willkommen in unserem Mandantenportal!</b>
                <br />
                <br />
                Bitte überprüfen Sie Ihre persönlichen Daten und vergeben Sie
                ein neues Passwort, um die Sicherheit Ihres Kontos zu
                gewährleisten.m
              </Box>
            </Grid>

            <Grid item sx={{ width: 300, mt: 10 }}>
              <TextField
                label='Titel'
                size='small'
                fullWidth
                select
                value={clientEdit.idAcademicTitle}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setClientEdit({
                    ...clientEdit,
                    idAcademicTitle: Number(event.target.value),
                  })
                }
                required
              >
                {academicTitles.map(academicTitle => (
                  <MenuItem
                    key={`idAcademicTitle-${academicTitle.idAcademicTitle}`}
                    value={academicTitle.idAcademicTitle}
                  >
                    {academicTitle.AcademicTitle}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item sx={{ width: 300, mt: 1 }}>
              <TextField
                label='Geschlecht'
                size='small'
                fullWidth
                select
                value={clientEdit.idGender}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setClientEdit({
                    ...clientEdit,
                    idGender: Number(event.target.value),
                  })
                }
                required
              >
                {genders.map(gender => (
                  <MenuItem
                    key={`idGender-${gender.idGender}`}
                    value={gender.idGender}
                  >
                    {gender.Gender}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item sx={{ width: 300, mt: 5 }}>
              <TextField
                label='Vorname'
                size='small'
                value={clientEdit.FirstName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setClientEdit({
                    ...clientEdit,
                    FirstName: event.target.value,
                  })
                }
                error={clientEdit.FirstName === ''}
                fullWidth
                required
              />
            </Grid>

            <Grid item sx={{ width: 300, mt: 1 }}>
              <TextField
                label='Nachname'
                size='small'
                value={clientEdit.LastName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setClientEdit({
                    ...clientEdit,
                    LastName: event.target.value,
                  })
                }
                error={clientEdit.LastName === ''}
                fullWidth
                required
              />
            </Grid>

            <Grid item sx={{ width: 300, mt: 5 }}>
              <TextField
                label='Mobiltelefon'
                size='small'
                value={
                  clientEdit.PhoneNumber === null ? '' : clientEdit.PhoneNumber
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setClientEdit({
                    ...clientEdit,
                    PhoneNumber:
                      event.target.value === '' ? null : event.target.value,
                  })
                }
                fullWidth
              />
            </Grid>

            <Box sx={{ mt: 10 }} />
            <Grid
              item
              xs={3}
              style={{
                width: '80%',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <Grid
                container
                spacing={0}
                direction='column'
                alignItems='center'
                justifyContent='center'
              >
                <PasswordResetNew
                  passwordIsValid
                  setPasswordIsValid={setPasswordIsValid}
                  setNewPassword={setNewPassword}
                />
              </Grid>
            </Grid>

            <Alert sx={{ mt: 5, width: 300 }} severity='info' icon={false}>
              <FormControlLabel
                label={
                  <Typography component='span'>
                    Ich bin mit der Verarbeitung meiner Daten zur Interaktion
                    und zum Datenaustausch mit dem Mandantenportal von{' '}
                    <i>{configObject.company_name}</i> einverstanden. Die&nbsp;
                    <Typography
                      component='span'
                      onClick={handleDsgvoOpen}
                      sx={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                    >
                      Datenschutzerklärung
                    </Typography>
                    &nbsp;von <i>{configObject.company_name}</i> habe zur
                    Kenntnis genommen.
                  </Typography>
                }
                control={
                  <Checkbox
                    checked={isTermsAndConditionsChecked}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setIsTermsAndConditionsChecked(event.target.checked)
                    }
                    inputProps={{ 'aria-label': 'controlled' }}
                    required
                  />
                }
              />
            </Alert>

            <Box sx={{ mt: 15 }} />
            <Grid item style={{ width: '80%' }}>
              <div style={{ float: 'right' }}>
                <Button
                  size='small'
                  variant='contained'
                  color='primary'
                  type='submit'
                >
                  Anmelden
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};
