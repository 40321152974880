import { useState, useEffect } from 'react';

const session_token = localStorage.getItem('SessionToken');

export function uploadFetch(
  endpoint: string,
  isNew: boolean,
  uploadlObject: any,
  setFunction: Function,
  setWasSuccessfullyUploaded?: Function,
  wasSaved?: Function,
  setIsLoading?: Function
) {
  if (setIsLoading) {
    setIsLoading(true);
  }

  const requestOptions = {
    method: isNew ? 'POST' : 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + session_token,
    },
    body: JSON.stringify(uploadlObject),
  };
  fetch(`/api/${endpoint}`, requestOptions)
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      else if (res.status === 408) {
        window.location.reload();
      } 
      else {
        throw Error(
          `ERROR ${isNew ? 'POST' : 'PUT'} in ${endpoint}: ${res.status}`
        );
      }
    })
    .then(res => {
      if (setWasSuccessfullyUploaded) {
        setWasSuccessfullyUploaded(true);
      }
      if (wasSaved) {
        wasSaved(true);
      }
      if (setIsLoading) {
        setIsLoading(false);
      }
      setFunction(res);
    })
    .catch(error => {
      console.error(error);
      if (setWasSuccessfullyUploaded) {
        setWasSuccessfullyUploaded(false);
      }
      if (wasSaved) {
        wasSaved(false);
      }
      if (setIsLoading) {
        setIsLoading(false);
      }
    });
}

export function getFetch<S>(
  endpoint: string,
  idToGet: number | string | undefined | null,
  setFunction?: Function,
  setWasSuccessfully?: Function
) {
  let targetEndpoint = `/api/${endpoint}`;
  let tryFetch = true;

  if (endpoint.includes('/') && !endpoint.includes('?')) {
    targetEndpoint = targetEndpoint + `${idToGet}`;

    tryFetch = idToGet !== undefined;
  }

  const requestOptions = {
    headers: { Authorization: 'Bearer ' + session_token },
  };

  if (!tryFetch) {
    return null;
  } else {
    fetch(targetEndpoint, requestOptions)
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } 
        else if (res.status === 204) {
          return null;
        } 
        else if (res.status === 408) {
          window.location.reload();
        } 
        else {
          throw Error(`ERROR GET in ${endpoint}: ${res.status}`);
        }
      })
      .then(res => {
        if (setFunction) {
          setFunction(res);
        }
        if (setWasSuccessfully) {
          setWasSuccessfully(true);
        }
      })
      .catch(error => {
        console.error(error);
        if (setWasSuccessfully) {
          setWasSuccessfully(false);
        }
      });
  }
}

export function useFetch<S>(
  endpoint: string,
  targetId: number | string | null | undefined = undefined
): [S | undefined, Function, boolean] {
  const [responseValue, setResponseValue] = useState<S>();
  const [wasSuccessfully, setWasSuccessfully] = useState(true);

  const requestOptions = {
    headers: {
      Authorization: 'Bearer ' + session_token,
      'X-APIKEY-LOCAL': '2YoqmmvC5upGaBedZ12ES8pZYj7FZqY',
    },
  };

  useEffect(() => {
    let targetEndpoint = `/api${endpoint}`;
    let execute = true;

    if (targetId !== undefined) {
      if (targetId === null || targetId === -1) {
        execute = false;
      } else {
        targetEndpoint = targetEndpoint + `${targetId}`;
      }
    }

    if (execute) {
      fetch(targetEndpoint, requestOptions)
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } 
          else if (res.status === 204) {
            return null;
          } 
          else if (res.status === 408) {
            window.location.reload();
          } 
          else {
            throw Error(`ERROR GET in ${endpoint}: ${res.status}`);
          }
        })
        .then(res => {
          setResponseValue(res);
        })
        .catch(error => {
          console.error(error);
          setWasSuccessfully(false);
        });
    }
  }, []);

  return [responseValue, setResponseValue, wasSuccessfully];
}

export const customFetcher = async ({
  endpoint,
  method = 'GET',
  body,
  signal,
}: {
  endpoint: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  body?: unknown;
  signal?: AbortSignal;
}) => {
  const sessionToken = localStorage.getItem('SessionToken');
  const apiEndpoint = `/api${endpoint}`;

  const requestOptions: RequestInit = {
    method,
    body: body ? JSON.stringify(body) : null,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionToken}`,
      'X-APIKEY-LOCAL': '2YoqmmvC5upGaBedZ12ES8pZYj7FZqY',
    },
    signal,
  };

  const response = await fetch(apiEndpoint, requestOptions);

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};
