import React, { useCallback, useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DocumentRow } from './DocumentRow';
import { IDocument } from '../../../Interfaces/IDocument';
import { IMIMEType } from '../../../Interfaces/IMIMEType';
import { useDropzone } from 'react-dropzone';
import { ILegalTransactionFullObject } from '../../../Interfaces/ILegalTransaction';
import { IConfig } from '../../../Interfaces/IConfig';
import { useMimeTypes } from '../../../hooks/data';
import { Loader } from '../../generic/Loader';

interface IProps {
  configObject: IConfig;
  legalTransactionObject: ILegalTransactionFullObject;
  idLegalRequirement: number;
  documentArray: IDocument[];
  setDocumentArray: (documentArray: IDocument[]) => void;
  allowUpload: boolean;
  allowRemove: boolean;
  title: string;
  showReadPermission: boolean;
  handleClose?: () => void;
}

async function fileToBase64(file: File) {
  const result_base64 = await new Promise(resolve => {
    const fileReader = new FileReader();
    fileReader.onload = () => resolve(fileReader.result);
    fileReader.readAsDataURL(file);
  });
  return String(result_base64).split(',')[1];
}

export const DocumentOverview: React.FC<IProps> = ({
  configObject,
  legalTransactionObject,
  idLegalRequirement,
  documentArray,
  setDocumentArray,
  allowUpload,
  allowRemove,
  title,
  showReadPermission,
}) => {
  const { mimeTypes, isLoadingMimeTypes } = useMimeTypes();
  const isDesktop = useMediaQuery('(min-width:600px)');

  const [enableUpload, setEnableUpload] = useState(false);
  const inputUpload = useRef<HTMLInputElement | null>(null);

  const handleFilesUpload = async (acceptedFiles: File[]) => {
    const uploadArray: IDocument[] = [];
    let minIdOffset = -1;

    if (documentArray.length > 0) {
      const minIdArray = Math.min(
        ...documentArray.map(document => document.idDocument)
      );

      if (minIdArray <= minIdOffset) {
        minIdOffset = minIdArray - 1;
      }
    }

    let index = 0;

    for (const currentFile of acceptedFiles) {
      const fileName: string = String(currentFile['name']);
      const mimeType: IMIMEType | undefined = mimeTypes?.find(
        y => y.MIMEType === currentFile['type']
      );

      if (mimeType) {
        uploadArray.push({
          idDocument: minIdOffset - index,
          FileName: fileName,
          idMIMEType: mimeType.idMIMEType,
          Data: await fileToBase64(currentFile),
          idLegalRequirement: idLegalRequirement,
          everybodyCanRead: false,
          idContact: Number(localStorage.getItem('idContact')),
        } as IDocument);
      }
      index = index + 1;
    }

    setDocumentArray([...documentArray, ...uploadArray]);
  };

  const handleNormalUpload = (files: null | FileList) => {
    if (files !== null) {
      const acceptedFiles: File[] = Array.from(files);
      handleFilesUpload(acceptedFiles);
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      handleFilesUpload(acceptedFiles);
      setEnableUpload(false);
    },
    [documentArray]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
  });

  if (isLoadingMimeTypes) {
    return <Loader />;
  }

  if (!mimeTypes) {
    return <Box>Fehler beim Laden...</Box>;
  }

  const dropZoneTSX = () => {
    return (
      <div>
        <input
          {...getInputProps()}
          accept={mimeTypes.map(mimeType => mimeType.MIMEType).join(', ')}
        />
        <Card>
          <CardContent>
            <Typography variant='body2'>Dokument(e) hier reinziehen</Typography>
            Erlaubte Datentypen
            <br />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Datentyp</TableCell>
                  <TableCell>Datenendung</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mimeTypes.map(mimeType => (
                  <TableRow>
                    <TableCell>{mimeType.MIMEType}</TableCell>
                    <TableCell>{mimeType.FileExtension}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>
    );
  };

  return (
    <div {...getRootProps()}>
      <input
        hidden
        ref={inputUpload}
        type='file'
        accept={mimeTypes.map(mimeType => mimeType.MIMEType).join(', ')}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleNormalUpload(event.target.files)
        }
      />
      <Typography variant='h5'>
        {title}
        {allowUpload && (
          <Button
            variant='outlined'
            title='Daten über Auswähl hochladen'
            onClick={() => inputUpload !== null && inputUpload.current?.click()}
            sx={{ float: 'right' }}
          >
            Hochladen
          </Button>
        )}
        {allowUpload && (
          <>
            <br />
            <Typography variant='caption'>
              (Hier können Sie Ihre Dokumente per <i>Drag & Drop</i> hochladen)
            </Typography>
          </>
        )}
      </Typography>

      {(isDragActive || enableUpload) && allowUpload && dropZoneTSX()}

      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 80 }}>Nr.</TableCell>
            {showReadPermission && <TableCell>Zugriff</TableCell>}
            <TableCell>Dateiname</TableCell>
            <TableCell sx={{ width: isDesktop ? 100 : undefined }}>
              {isDesktop ? <>Aktionen</> : <></>}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documentArray.map(document => (
            <DocumentRow
              key={`idDocument-${document.idDocument}`}
              configObject={configObject}
              legalTransactionObject={legalTransactionObject}
              documentObject={document}
              documentArray={documentArray}
              setDocumentArray={setDocumentArray}
              allowRemove={allowRemove}
              showReadPermission={showReadPermission}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
