import React, { useState } from 'react';
import {
  Button,
  Dialog,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { Delete, Download } from '@mui/icons-material';
import { IDocument } from '../../../Interfaces/IDocument';
import { ILegalTransactionFullObject } from '../../../Interfaces/ILegalTransaction';
import { LegalRequirementRequestFiles } from '../LegalTransaction/LegalPhase/LegalRequirementRequestFiles';
import { IConfig } from '../../../Interfaces/IConfig';

interface IProps {
  configObject: IConfig;
  legalTransactionObject: ILegalTransactionFullObject;
  documentObject: IDocument;
  documentArray: IDocument[];
  setDocumentArray: (documentArray: IDocument[]) => void;
  allowRemove: boolean;
  showReadPermission: boolean;
}

const base64Download = (localDocumentObject: IDocument) => {
  if (localDocumentObject.Data) {
    const byteCharacters = atob(localDocumentObject.Data);
    // Each character's code point (charCode) will be the value of the byte.
    // We can create an array of byte values by applying this using the .charCodeAt method for each character in the string.
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    //You can convert this array of byte values into a real typed byte array by passing it to the Uint8Array constructor.
    const byteArray = new Uint8Array(byteNumbers);
    // This in turn can be converted to a BLOB by wrapping it in an array and passing it to the Blob constructor.
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    // Erstelle Link zum BLOB
    const blobUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = localDocumentObject.FileName;
    a.click();
  }
};

export const DocumentRow: React.FC<IProps> = ({
  configObject,
  legalTransactionObject,
  documentObject,
  documentArray,
  setDocumentArray,
  allowRemove,
  showReadPermission,
}) => {
  const [currentObject, setCurrentObject] = useState(documentObject);
  const isDesktop = useMediaQuery('(min-width:600px)');
  const [isOpenRequestFile, setIsOpenRequestFile] = useState(false);

  const handleDownload = () => {
    if (currentObject.Data) {
      base64Download(currentObject);
    } else {
      setIsOpenRequestFile(true);
    }
  };

  const handleRemove = () => {
    setDocumentArray([
      ...documentArray.filter(
        document => document.idDocument !== currentObject.idDocument
      ),
    ]);
  };

  const handleChangePermission = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updatedObject = {
      ...currentObject,
      everybodyCanRead: event.target.value === 'true',
    };
    setDocumentArray([
      ...documentArray.map(document =>
        document.idDocument === updatedObject.idDocument
          ? updatedObject
          : document
      ),
    ]);
    setCurrentObject(updatedObject);
  };

  return (
    <>
      <Dialog
        open={isOpenRequestFile}
        onClose={() => setIsOpenRequestFile(false)}
        maxWidth='lg'
        fullWidth
      >
        <LegalRequirementRequestFiles
          configObject={configObject}
          idDocument={currentObject.idDocument}
          legalTransaction={legalTransactionObject}
          documentArray={documentArray.filter(x => x.idDocument > 0)}
          setIsOpen={setIsOpenRequestFile}
        />
      </Dialog>

      <TableRow>
        <TableCell>
          {currentObject.idDocument < 0 ? <>Neu</> : currentObject.idDocument}
        </TableCell>

        {showReadPermission && (
          <TableCell sx={{ width: 200 }}>
            {currentObject.idDocument < 0 ? (
              <TextField
                select
                label='Zugriff'
                sx={{ width: 200 }}
                size='small'
                value={currentObject.everybodyCanRead ? 'true' : 'false'}
                onChange={handleChangePermission}
              >
                <MenuItem key={`everybodyCanRead-false`} value='false'>
                  Nur ich (und die Kanzlei)
                </MenuItem>
                <MenuItem key={`everybodyCanRead-true`} value='true'>
                  Alle Beteiligte
                </MenuItem>
              </TextField>
            ) : currentObject.everybodyCanRead ? (
              'Alle Beteiligte'
            ) : (
              'Nur ich (und die Kanzlei)'
            )}
          </TableCell>
        )}

        <TableCell>{currentObject.FileName}</TableCell>
        <TableCell>
          {isDesktop ? (
            <Button variant='contained' onClick={handleDownload}>
              Anfragen
            </Button>
          ) : (
            <IconButton onClick={handleDownload}>
              <Download />
            </IconButton>
          )}
          {(currentObject.idDocument < 0 || allowRemove) && (
            <>
              {isDesktop ? (
                <Button variant='contained' onClick={handleRemove}>
                  Entfernen
                </Button>
              ) : (
                <IconButton onClick={handleRemove}>
                  <Delete />
                </IconButton>
              )}
            </>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};
