import React from 'react';
import NavBarPersistent from '../private/NavBarPersistent';
import { LegalTransactionOverview } from '../private/LegalTransaction/LegalTransactionOverview';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { IConfig } from '../../Interfaces/IConfig';
import { DownloadArea } from '../private/DonwloadArea/DownloadArea';

import { ClientEdit } from '../private/Client/ClientEdit';

interface IProps {
  configObject: IConfig;
}

export const PrivatePageRouter: React.FC<IProps> = ({ configObject }) => {
  return (
    <>
      <Router>
        <NavBarPersistent configObject={configObject}>
          <Routes>
            <Route
              index
              element={<LegalTransactionOverview configObject={configObject} />}
            />
            <Route
              path='/:idLegalTransaction'
              element={<LegalTransactionOverview configObject={configObject} />}
            />
            <Route
              path='/:idLegalTransaction/:view'
              element={<LegalTransactionOverview configObject={configObject} />}
            />
            <Route path='/account' element={<ClientEdit />} />
            <Route path='/download_area' element={<DownloadArea />} />
          </Routes>
        </NavBarPersistent>
      </Router>
    </>
  );
};
