import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Card,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { uploadFetch, useFetch } from '../../../hooks/useFetch';
import { CustomCircularProgress } from '../../generic/CustomCircularProgress';
import { IContact } from '../../../Interfaces/IContact';
import { ClientPassword } from './ClientPassword';
import md5 from 'md5';
import { getIsEmail } from '../../core/generic_helper';
import { useAcademicTitles, useGenders } from '../../../hooks/data';
import { Loader } from '../../generic/Loader';

export const ClientEdit: React.FC = () => {
  const { genders, isLoadingGenders } = useGenders();
  const { academicTitles, isLoadingAcademicTitles } = useAcademicTitles();

  const [clientEdit, setClientEdit, wasSuccessfully] =
    useFetch<IContact>('/contact');
  const isDesktop = useMediaQuery('(min-width:600px)');
  const [isNotValidated, setIsNotValidated] = useState(true);

  const [currentPassword, setCurrentPassword] = useState('');
  const [isNewPwValidated, setIsNewPwValidated] = useState(false);

  const [orgEmail, setOrgEmail] = useState<string | null>(null);
  const [isValidatedObject, setIsValidatedObject] = useState<
    undefined | { IsValidated: boolean }
  >(undefined);

  const [isOpenValidate, setIsOpenValidate] = useState(false);
  const [wasSuccessfullyValidated, setWasSuccessfullyValidated] =
    useState(true);

  const [isLoadingSaved, setIsLoadingSaved] = useState(false);
  const [wasSaved, setWasSaved] = useState(false);
  const [wasSuccessfullySaved, setWasSuccessfullySaved] = useState(false);

  useEffect(() => {
    if (isNotValidated && clientEdit !== undefined) {
      setOrgEmail(clientEdit.Email);
    }
  }, [clientEdit]);

  const handleCheckPw = () => {
    if (currentPassword !== '') {
      setIsOpenValidate(true);
      uploadFetch(
        '/contact/validatePassword',
        true,
        { Password: md5(currentPassword) },
        setIsValidatedObject,
        setWasSuccessfullyValidated
      );
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleCheckPw();
  };

  const handleCloseDialog = () => {
    if (isValidatedObject !== undefined && isValidatedObject.IsValidated) {
      setCurrentPassword('validated');
      setIsNotValidated(false);
    } else {
      setCurrentPassword('');
    }

    setIsOpenValidate(false);
  };

  const getIsDisabledSave = () => {
    if (clientEdit === undefined) {
      return true;
    } else {
      return (
        clientEdit.Email === '' ||
        !getIsEmail(clientEdit.Email) ||
        clientEdit.FirstName === '' ||
        clientEdit.LastName === '' ||
        (clientEdit.Password !== undefined && !isNewPwValidated)
      );
    }
  };

  const handleSave = () => {
    const uplaodObject = { ...clientEdit };

    if ('allowDataChange' in uplaodObject) {
      uplaodObject.allowDataChange = false;
    }

    setIsLoadingSaved(true);
    uploadFetch(
      '/contact',
      false,
      uplaodObject,
      (localContact: IContact) => {
        setClientEdit(localContact);
        setOrgEmail(localContact.Email);
        setIsNotValidated(true);
        setCurrentPassword('');
        setIsLoadingSaved(false);
      },
      setWasSuccessfullySaved,
      setWasSaved
    );
  };

  if (isLoadingGenders || isLoadingAcademicTitles) {
    return <Loader />;
  }

  if (!genders || !academicTitles) {
    return <Alert severity='error'>Es ist ein Fehler aufgetreten!</Alert>;
  }

  const dialogContextTsx = () => {
    if (
      !wasSuccessfullyValidated ||
      (isValidatedObject && isValidatedObject.IsValidated === false)
    ) {
      return (
        <>
          <DialogContent>
            <Alert severity='warning'>
              Das eingegebene Passwort konnte nicht validiert werden!
              <br />
              {!wasSuccessfullyValidated && <>(E1000)</>}
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} variant='contained'>
              Schließen
            </Button>
          </DialogActions>
        </>
      );
    } else if (!isValidatedObject) {
      return <CustomCircularProgress />;
    } else {
      return (
        <>
          <DialogContent>
            <Alert severity='success'>
              Das eingegebene Passwort konnte erfolgreich validiert werden!
              <br />
              Sie können nun Ihre Daten anpassen.
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} variant='contained'>
              Schließen
            </Button>
          </DialogActions>
        </>
      );
    }
  };

  if (!wasSuccessfully) {
    return <Alert severity='error'>Es ist ein Fehler aufgetreten!</Alert>;
  } else if (!clientEdit) {
    return <CustomCircularProgress />;
  } else {
    return (
      <>
        <Dialog
          open={isLoadingSaved && wasSuccessfullySaved}
          onClose={handleCloseDialog}
          maxWidth='sm'
          fullWidth
        >
          <CustomCircularProgress />
        </Dialog>

        <Dialog
          open={isOpenValidate}
          onClose={handleCloseDialog}
          maxWidth='sm'
          fullWidth
        >
          {dialogContextTsx()}
        </Dialog>

        <Box sx={{ m: isDesktop ? 3 : 0 }}>
          <form onSubmit={handleSubmit}>
            <Typography variant='h5'>
              Mein Benutzerkonto bearbeiten
              {!isNotValidated && (
                <Button
                  onClick={handleSave}
                  disabled={getIsDisabledSave()}
                  sx={{ float: 'right' }}
                  variant='contained'
                >
                  Speichern
                </Button>
              )}
            </Typography>

            <Collapse
              sx={{ mt: 2 }}
              in={
                !isNotValidated &&
                clientEdit.Email !== '' &&
                orgEmail?.toLocaleLowerCase() !==
                  clientEdit!.Email.toLocaleLowerCase()
              }
            >
              <Alert severity='warning'>
                Die Änderung Ihrer E-Mail-Adresse wird von unserem Zentralserver
                geprüft und kann unter Umständen abgelehnt werden.
              </Alert>
            </Collapse>

            <Collapse sx={{ mt: 2 }} in={wasSaved}>
              <Alert
                severity={wasSuccessfullySaved ? 'success' : 'error'}
                action={
                  <Button
                    onClick={() => setWasSaved(false)}
                    color={wasSuccessfullySaved ? 'success' : 'error'}
                    variant='outlined'
                  >
                    Schließen
                  </Button>
                }
              >
                {wasSuccessfullySaved ? (
                  <>
                    Ihre Änderungen wurden erfolgreich übermittelt. Sofern Ihre
                    Änderungen angenommen werden, erhalten Sie eine E-Mail.
                    <br />
                    Sofern Sie ebenfalls Ihre E-Mail-Adresse verändert haben,
                    erhalten Sie eien gesonderte E-Mail für diese Änderung.
                  </>
                ) : (
                  <>
                    Es ist ein Fehler aufgetreten!
                    <br /> Die Fehlerinformationen wurden an die{' '}
                    <a href='https://www.lextorbyte.de/' target='_blank'>
                      LeXtorByte UG (haftungsbeschränkt)
                    </a>{' '}
                    übermittelt.
                  </>
                )}
              </Alert>
            </Collapse>

            <Box sx={{ mt: 2 }} />
            <Card variant='outlined'>
              <Box sx={{ m: 2 }}>
                <Typography variant='caption'>Entsperren</Typography>
                <br />
                Zum Bearbeiten Ihrer persönlichen Daten müssen Sie Ihr Passwort
                erneut eingeben.
                <Box sx={{ mt: 2 }} />
                <TextField
                  label='Passwort'
                  type='password'
                  size='small'
                  fullWidth
                  disabled={!isNotValidated}
                  value={currentPassword}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setCurrentPassword(event.target.value)
                  }
                />
                <Button
                  onClick={handleCheckPw}
                  sx={{ float: 'right', mt: 2, mb: 2 }}
                  variant='contained'
                  disabled={!isNotValidated}
                >
                  Entsperren
                </Button>
              </Box>
            </Card>
          </form>

          <Box sx={{ mt: 5 }} />
          <Card variant='outlined'>
            <Typography variant='caption' sx={{ ml: 1 }}>
              Kontaktinformationen
            </Typography>
            <Box sx={{ m: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={2}>
                  <TextField
                    label='Titel'
                    size='small'
                    fullWidth
                    disabled={isNotValidated}
                    select
                    value={clientEdit.idAcademicTitle}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setClientEdit({
                        ...clientEdit,
                        idAcademicTitle: Number(event.target.value),
                      })
                    }
                  >
                    {academicTitles.map(academicTitle => (
                      <MenuItem
                        key={`idAcademicTitle-${academicTitle.idAcademicTitle}`}
                        value={academicTitle.idAcademicTitle}
                      >
                        {academicTitle.AcademicTitle}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={6} sm={2}>
                  <TextField
                    label='Geschlecht'
                    size='small'
                    fullWidth
                    disabled={isNotValidated}
                    select
                    value={clientEdit.idGender}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setClientEdit({
                        ...clientEdit,
                        idGender: Number(event.target.value),
                      })
                    }
                  >
                    {genders.map(gender => (
                      <MenuItem
                        key={`idGender-${gender.idGender}`}
                        value={gender.idGender}
                      >
                        {gender.Gender}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={6} sm={4}>
                  <TextField
                    label='Vorname'
                    size='small'
                    value={clientEdit.FirstName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setClientEdit({
                        ...clientEdit,
                        FirstName: event.target.value,
                      })
                    }
                    error={clientEdit.FirstName === ''}
                    fullWidth
                    disabled={isNotValidated}
                  />
                </Grid>

                <Grid item xs={6} sm={4}>
                  <TextField
                    label='Nachname'
                    size='small'
                    value={clientEdit.LastName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setClientEdit({
                        ...clientEdit,
                        LastName: event.target.value,
                      })
                    }
                    error={clientEdit.LastName === ''}
                    fullWidth
                    disabled={isNotValidated}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    type='email'
                    label='E-Mail'
                    size='small'
                    value={clientEdit.Email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setClientEdit({
                        ...clientEdit,
                        Email: event.target.value,
                      })
                    }
                    error={
                      clientEdit.Email === '' || !getIsEmail(clientEdit.Email)
                    }
                    fullWidth
                    disabled={isNotValidated}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label='Mobiltelefon'
                    size='small'
                    value={
                      clientEdit.PhoneNumber === null
                        ? ''
                        : clientEdit.PhoneNumber
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setClientEdit({
                        ...clientEdit,
                        PhoneNumber:
                          event.target.value === '' ? null : event.target.value,
                      })
                    }
                    fullWidth
                    disabled={isNotValidated}
                  />
                </Grid>
              </Grid>
            </Box>
          </Card>

          <Box sx={{ mt: 5 }} />
          <Card variant='outlined'>
            <Box sx={{ m: 2 }}>
              <Typography variant='caption'>Passwort ändern</Typography>
              <Box sx={{ mt: 1 }} />
              <ClientPassword
                key={`reload-over-wasSaved-${wasSaved}`}
                editClient={clientEdit}
                setUserObject={setClientEdit}
                disabled={isNotValidated}
                setIsValid={setIsNewPwValidated}
              />
            </Box>
          </Card>
        </Box>
      </>
    );
  }
};
