import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import {
  IDraftMessage,
  IDraftMessageFullObject,
} from '../../../../Interfaces/IDraftMessage';
import { ILegalTransactionFullObject } from '../../../../Interfaces/ILegalTransaction';
import { uploadFetch } from '../../../../hooks/useFetch';
import { DocumentOverview } from '../../Document/DocumentOverview';
import { IDocument } from '../../../../Interfaces/IDocument';
import { IConfig } from '../../../../Interfaces/IConfig';
import { Loader } from '../../../generic/Loader';

interface IProps {
  configObject: IConfig;
  legalTransactionObject: ILegalTransactionFullObject;
  idDraftMessageParent: number | null;
  setIdDraftMessageParent: Function;
  draftMessageArray: IDraftMessage[];
  setDraftMessageArray: Function;
}

const getNewMessage = (
  legalTransactionObject: ILegalTransactionFullObject,
  draftMessageArray: IDraftMessage[]
) => {
  let tmpId = Math.min(...draftMessageArray.map(x => x.idDraftMessage)) - 1;

  if (tmpId >= 0) {
    tmpId = -1;
  }

  return {
    DraftMessage: '',
    idDraftMessage: tmpId,
    idDraftMessageState: 1,
    idLegalTransaction: legalTransactionObject.idLegalTransaction,
    idUser: null,
    idContact: Number(localStorage.getItem('idContact')),
    Partent_idDraftMessage: null,
    DocumentArray: [],
  } as IDraftMessageFullObject;
};

export const NewDraftMessage: React.FC<IProps> = ({
  configObject,
  legalTransactionObject,
  draftMessageArray,
  idDraftMessageParent,
  setIdDraftMessageParent,
}) => {
  const [parentObject, setPrentObject] = useState<IDraftMessage | undefined>(
    undefined
  );
  const [messageObject, setMessageObject] = useState<IDraftMessage>(
    getNewMessage(legalTransactionObject, draftMessageArray)
  );
  const [documentArray, setDocumentArray] = useState<IDocument[]>([]);
  const [isOpenNew, setIsOpenNew] = useState(false);
  const [wasSaved, setWasSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [wasSavedSuccessfully, setWasSavedSuccessfully] = useState(true);

  const handleOpen = () => {
    setIsLoading(false);
    setWasSaved(false);
    setMessageObject(getNewMessage(legalTransactionObject, draftMessageArray));

    if (idDraftMessageParent) {
      setPrentObject(
        draftMessageArray.find(x => x.idDraftMessage === idDraftMessageParent)
      );
    }
    setIsOpenNew(true);
  };

  const handleClose = () => {
    setIdDraftMessageParent(null);
    setPrentObject(undefined);
    setIsOpenNew(false);
  };

  const handleSave = () => {
    const tmpObject = {
      ...messageObject,
      Created_at: new Date().toISOString(),
      DocumentArray: documentArray,
    };

    if (parentObject) {
      tmpObject.Partent_idDraftMessage = parentObject.idDraftMessage;
    }

    uploadFetch(
      '/draftmessage',
      true,
      tmpObject,
      () => {},
      setWasSavedSuccessfully,
      setWasSaved,
      setIsLoading
    );
  };

  useEffect(() => {
    if (idDraftMessageParent) {
      handleOpen();
    }
  }, [idDraftMessageParent]);

  const contextTsx = () => {
    if (isLoading) {
      return (
        <DialogContent>
          <Loader />
        </DialogContent>
      );
    } else if (wasSaved) {
      if (wasSavedSuccessfully) {
        return (
          <>
            <DialogContent>
              <Alert severity='success'>
                Ihr Kommentar wurde erfolgreich hocheladen!
                <br /> Es kann bis zu fünf Minuten dauern, bis der Kommentar
                gelistet wird.
              </Alert>
            </DialogContent>
            <DialogActions>
              <Button variant='contained' onClick={handleClose}>
                Schließen
              </Button>
            </DialogActions>
          </>
        );
      } else {
        return (
          <>
            <DialogContent>
              <Alert severity='error'>
                Es ist ein Fehler aufgetreten!
                <br /> Die Fehlerinformationen wurden an die{' '}
                <a href='https://www.lextorbyte.de/' target='_blank'>
                  LeXtorByte UG (haftungsbeschränkt)
                </a>{' '}
                übermittelt.
              </Alert>
            </DialogContent>
            <DialogActions>
              <Button variant='contained' onClick={handleClose}>
                Schließen
              </Button>
            </DialogActions>
          </>
        );
      }
    } else {
      return (
        <>
          <DialogContent>
            {parentObject && (
              <>
                <Paper
                  variant='outlined'
                  sx={{ m: 2, backgroundColor: grey[50] }}
                >
                  <Box sx={{ m: 2 }}>
                    <i>{parentObject.DraftMessage}</i>
                  </Box>
                </Paper>
              </>
            )}

            <TextField
              sx={{ mt: 2 }}
              label={parentObject ? 'Antwort' : 'Kommentar'}
              value={messageObject.DraftMessage}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setMessageObject({
                  ...messageObject,
                  DraftMessage: event.target.value,
                })
              }
              size='small'
              fullWidth
              rows={5}
              multiline
              error={messageObject.DraftMessage === ''}
            />
            <Box sx={{ mt: 5 }} />
            <DocumentOverview
              allowUpload
              allowRemove
              configObject={configObject}
              idLegalRequirement={-1}
              legalTransactionObject={legalTransactionObject}
              showReadPermission={false}
              documentArray={documentArray}
              setDocumentArray={setDocumentArray}
              title='Dokumente zum Kommentar'
            />
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={handleClose}>
              Abbruch
            </Button>
            <Button
              variant='contained'
              onClick={handleSave}
              disabled={messageObject.DraftMessage === ''}
            >
              Übernehmen
            </Button>
          </DialogActions>
        </>
      );
    }
  };

  return (
    <>
      <Dialog open={isOpenNew} onClose={handleClose} maxWidth='sm' fullWidth>
        <DialogTitle>
          {parentObject ? 'Antwort zu' : 'Neuer Kommentar'}
        </DialogTitle>
        {contextTsx()}
      </Dialog>

      <Button variant='outlined' sx={{ float: 'right' }} onClick={handleOpen}>
        Kommentar hinzufügen
      </Button>
    </>
  );
};
