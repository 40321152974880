import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { HelpOverview } from '../private/LegalTransaction/HelpOverview';
import { IConfig } from '../../Interfaces/IConfig';

interface IProps {
  configObject: IConfig;
}

export const RequestFileHelp: React.FC<IProps> = ({ configObject }) => {
  const [isOpenHelpDialog, setIsOpenHelpDialog] = useState(false);
  return (
    <>
      <HelpOverview
        configObject={configObject}
        isOpenParent={isOpenHelpDialog}
        setIsOpenParent={setIsOpenHelpDialog}
        openOnPage='download_documents'
        disableButton
      />

      <Typography
        component='span'
        onClick={() => setIsOpenHelpDialog(true)}
        sx={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
      >
        anfragen
      </Typography>
    </>
  );
};
