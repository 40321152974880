import React, { useState } from 'react';
import { IDocument } from '../../../../Interfaces/IDocument';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { ISystem } from '../../../../Interfaces/ISystem';
import { uploadFetch } from '../../../../hooks/useFetch';
import { CustomCircularProgress } from '../../../generic/CustomCircularProgress';

interface IProps {
  idLegalTransaction: number;
  documentArrayClient: IDocument[];
  setDocumentArrayClient: Function;
  isOpen: boolean;
  setIsOpen: Function;
}

export const LegalRequirementUploadFiles: React.FC<IProps> = ({
  idLegalTransaction,
  documentArrayClient,
  setDocumentArrayClient,
  isOpen,
  setIsOpen,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [wasSuccessfully, setWasSuccessfully] = useState(0);

  const handleCloseAbort = () => {
    setDocumentArrayClient([
      ...documentArrayClient.filter(document => document.idDocument > 0),
    ]);
    setIsOpen(false);
    setCurrentPage(0);
  };

  const handleRequestFiles = () => {
    const uploadSync: ISystem = {
      idContact: Number(localStorage.getItem('idContact')),
      idLegalTransaction: idLegalTransaction,
      SyncType: 'UploadFile',
      Data: documentArrayClient.filter(x => x.idDocument < 0),
    };
    uploadFetch(
      '/system',
      true,
      uploadSync,
      () => setCurrentPage(1),
      setWasSuccessfully,
      () => {},
      setIsLoading
    );
  };

  const contextDialogBodyTsx = () => {
    if (isLoading) {
      return (
        <DialogContent>
          <CustomCircularProgress />
        </DialogContent>
      );
    } else if (currentPage === 0) {
      return (
        <>
          <DialogContent>
            Sind Sie sich sicher, dass Sie die folgenden Daten hochladen wollen?
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nr.</TableCell>
                  <TableCell>Zugriff</TableCell>
                  <TableCell>Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {documentArrayClient
                  .filter(x => x.idDocument < 0)
                  .map((x, i) => (
                    <TableRow>
                      <TableCell>{i + 1}</TableCell>
                      <TableCell>
                        {x.everybodyCanRead
                          ? 'Alle Beteiligte'
                          : 'Nur ich (und die Kanzlei)'}
                      </TableCell>
                      <TableCell>{x.FileName}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <br></br>Ein Löschen der Daten ist zum späteren Zeitpunkt nur durch
            einen Mitarbeiter möglich.
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={handleCloseAbort}>
              Schlißen
            </Button>
            <Button variant='contained' onClick={handleRequestFiles}>
              Hochladen
            </Button>
          </DialogActions>
        </>
      );
    } else if (currentPage === 1 && wasSuccessfully) {
      return (
        <>
          <DialogContent>
            <Alert severity='success'>
              Die Dokumente wurden erfolgreich hochgeladen!
              <br /> Es kann bis zu fünf Minuten dauern, bis die Dokumente im
              Systen angezeigt werden.
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' onClick={handleCloseAbort}>
              Schließen
            </Button>
          </DialogActions>
        </>
      );
    } else if (currentPage === 1 && !wasSuccessfully) {
      return (
        <>
          <DialogContent>
            <Alert severity='error'>
              Es ist ein Fehler aufgetreten!
              <br /> Die Fehlerinformationen wurden an die{' '}
              <a href='https://www.lextorbyte.de/' target='_blank'>
                LeXtorByte UG (haftungsbeschränkt)
              </a>{' '}
              übermittelt.
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' onClick={handleCloseAbort}>
              Schließen
            </Button>
          </DialogActions>
        </>
      );
    } else {
      return <>Fehler!</>;
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleCloseAbort}>
        <DialogTitle>Daten hochladen</DialogTitle>
        {contextDialogBodyTsx()}
      </Dialog>
    </>
  );
};
