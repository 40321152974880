import React, { useMemo } from 'react';
import {
  Alert,
  Badge,
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { DataGrid, GridColDef, deDE } from '@mui/x-data-grid';
import { LegalTransactionView } from './LegalTransactionView';
import { FullScreenDialog } from '../../core/FullScreenDialog';
import { useNavigate, useParams } from 'react-router-dom';
import { ILegalTransactionMeeting } from '../../../Interfaces/ILegalTransactionMeeting';
import { castWithZeroTimezone } from '../../core/generic_helper';
import { HelpOverview } from './HelpOverview';
import { IConfig } from '../../../Interfaces/IConfig';
import {
  useLegalTransactionProgresses,
  useLegalTransactions,
  useMetadata,
} from '../../../hooks/data';
import type { ILegalTransactionFullObject } from '../../../Interfaces/ILegalTransaction';
import type {
  ChangeNotification,
  ILegalTransactionMetadata,
} from '../../../Interfaces/ILegalTransactionMetadata';

const isMeetingDefined = (
  meeting?: ILegalTransactionMeeting
): meeting is ILegalTransactionMeeting => {
  return meeting !== undefined;
};

const columnsDesktop: GridColDef[] = [
  {
    field: 'idLegalTransaction',
    headerName: 'Nr.',
    width: 90,
  },
  {
    field: 'LegalTransactionType',
    headerName: 'Art',
    width: 200,
  },
  {
    field: 'LegalTransactionProgress',
    headerName: 'Phase',
    width: 150,
  },
  {
    field: 'Title',
    headerName: 'Bezeichnung',
    flex: 1,
    width: 200,
    renderCell: ({ row }) => {
      const notifications = row.metadata?.changeNotifications || [];
      const isNewTransaction = notifications.some(
        (notification: ChangeNotification) =>
          notification.type === 'new-transaction' && !notification.viewed
      );

      const unreadCount = notifications.filter(
        (notification: ChangeNotification) => !notification.viewed
      ).length;

      return (
        <Box>
          <Typography variant='body2' component='span'>
            {row.Title}
          </Typography>
          {isNewTransaction ? (
            <Badge badgeContent='Neu' color='primary' sx={{ ml: 2, mb: 1 }} />
          ) : unreadCount !== 0 ? (
            <Badge
              badgeContent={unreadCount}
              color='primary'
              sx={{ ml: 2, mb: 1 }}
            />
          ) : null}
        </Box>
      );
    },
  },
];

const columnsMobile: GridColDef[] = [
  {
    field: 'idLegalTransaction',
    headerName: 'Nr.',
    width: 90,
  },
  {
    field: 'Title',
    headerName: 'Bezeichnung',
    flex: 1,
  },
  {
    field: 'action',
    headerName: '',
    width: 60,
    renderCell: () => (
      <IconButton>
        <Search />
      </IconButton>
    ),
  },
];

interface IProps {
  configObject: IConfig;
}

export type LegalTransactionWithMetadata = ILegalTransactionFullObject & {
  metadata: ILegalTransactionMetadata | undefined;
};

export const LegalTransactionOverview: React.FC<IProps> = ({
  configObject,
}) => {
  const params = useParams();
  const navigation = useNavigate();
  const isDesktop = useMediaQuery('(min-width:600px)');

  const { metadata, isLoadingMetadata } = useMetadata();

  const { legalTransactions, isLoadingLegalTransactions } =
    useLegalTransactions();

  const { legalTransactionProgresses, isLoadingLegalTransactionsProgresses } =
    useLegalTransactionProgresses();

  const currentLegalTransactionId = params.idLegalTransaction
    ? Number(params.idLegalTransaction)
    : undefined;

  const legalTransactionsWithMetadata:
    | LegalTransactionWithMetadata[]
    | undefined = useMemo(() => {
    return legalTransactions?.map(legalTransaction => {
      return {
        ...legalTransaction,
        metadata: metadata?.find(
          metadata =>
            metadata.legalTransactionId === legalTransaction.idLegalTransaction
        ),
      };
    });
  }, [legalTransactions, metadata]);

  const currentLegalTransaction = legalTransactionsWithMetadata?.find(
    legalTransaction =>
      legalTransaction.idLegalTransaction === currentLegalTransactionId
  );

  const meetings = useMemo(
    () =>
      legalTransactionsWithMetadata
        ? legalTransactionsWithMetadata
            .map(
              legalTransaction => legalTransaction.LegalTransactionMeetingArray
            )
            .flat()
            .filter(isMeetingDefined)
            .filter(meeting => new Date(meeting.Meeting_at) > new Date())
        : [],
    [legalTransactionsWithMetadata]
  );

  const handleOnClick = (idLegalTransaction: number) => {
    navigation(`/${idLegalTransaction}`);

    // Add here
  };

  const handleClose = () => {
    navigation(`/`);
  };

  if (
    isLoadingLegalTransactions ||
    isLoadingLegalTransactionsProgresses ||
    isLoadingMetadata
  ) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!legalTransactionsWithMetadata || !legalTransactionProgresses) {
    return <div>Fehler beim Laden der Daten</div>;
  }

  return (
    <>
      <Box>
        {meetings.length !== 0 ? (
          <>
            <Typography variant='h4'>Ihre Termine</Typography>
            <Alert severity='success'>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Termin</TableCell>
                    <TableCell>Am</TableCell>
                    <TableCell>Vorgang</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {meetings.map(meeting => (
                    <TableRow>
                      <TableCell>{meeting.LegalTransactionMeeting}</TableCell>
                      <TableCell>
                        {new Date(
                          castWithZeroTimezone(meeting.Meeting_at)!
                        ).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        {
                          legalTransactionsWithMetadata.find(
                            x =>
                              x.idLegalTransaction ===
                              meeting.idLegalTransaction
                          )?.Title
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Alert>
          </>
        ) : (
          <>
            <Typography variant='h4'>Meine Termine</Typography>
            <Alert severity='info'>
              Zurzeit sind keine Termine vereinbart.
            </Alert>
          </>
        )}
      </Box>

      <Box sx={{ mt: 8 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant='h4'>Meine Vorgänge</Typography>
          <HelpOverview configObject={configObject} />
        </Box>

        <Box sx={{ mt: 2 }}>
          <DataGrid
            autoHeight
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            rows={legalTransactionsWithMetadata}
            columns={isDesktop ? columnsDesktop : columnsMobile}
            getRowId={row => row.idLegalTransaction}
            onRowClick={params =>
              handleOnClick(Number(params.row.idLegalTransaction))
            }
          />
        </Box>
      </Box>

      {currentLegalTransaction && (
        <FullScreenDialog isOpen onClose={handleClose} title='Vorgang'>
          <LegalTransactionView
            configObject={configObject}
            key={`idLegalTransaction-${currentLegalTransaction.idLegalTransaction}`}
            legalTransaction={currentLegalTransaction}
            legalTransactionProgresses={legalTransactionProgresses}
            view={params.view}
            handleClose={handleClose}
          />
        </FullScreenDialog>
      )}
    </>
  );
};
