import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import { ILegalPhase } from '../../../../Interfaces/ILegalPhase';
import { ILegalRequirement } from '../../../../Interfaces/ILegalRequirement';

interface IProps {
  currentObject: ILegalPhase | ILegalRequirement;
}

export const LegalInfoText: React.FC<IProps> = ({ currentObject }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>Infotext</DialogTitle>
        <DialogContent>{currentObject.InfoText}</DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Schließen</Button>
        </DialogActions>
      </Dialog>

      {currentObject.InfoText && (
        <IconButton
          title='Infotext zur Phase'
          size='small'
          onClick={() => setIsOpen(true)}
        >
          <HelpOutline />
        </IconButton>
      )}
    </>
  );
};
