import React, { useState } from 'react';
import { Alert, Grid, Typography } from '@mui/material';
import { LegalRequirementRow } from './LegalRequirementRow';
import { ILegalPhaseFullObject } from '../../../../Interfaces/ILegalPhase';
import { ILegalTransactionFullObject } from '../../../../Interfaces/ILegalTransaction';
import { LegalInfoText } from './LegalInfoText';
import { IConfig } from '../../../../Interfaces/IConfig';

interface IProps {
  configObject: IConfig;
  legalTransaction: ILegalTransactionFullObject;
  legalPhaseFullObject: ILegalPhaseFullObject;
  legalPhaseFullObjectArray: ILegalPhaseFullObject[];
  setLegalPhaseFullObjectArray: Function;
}

export const LegalPhaseRow: React.FC<IProps> = props => {
  const [legalPhaseObject, setLegalPhaseObject] = useState(
    props.legalPhaseFullObject
  );
  const [legalReqArray, setLegalReqArray] = useState(
    props.legalPhaseFullObject.LegalRequirementArray
  );

  return (
    <>
      <Grid item xs={12} sm={12}>
        <Alert
          variant='standard'
          severity={legalPhaseObject.isComplete ? 'success' : 'info'}
          action={
            <>
              <LegalInfoText currentObject={legalPhaseObject} />
            </>
          }
        >
          {legalPhaseObject.LegalPhase}
          {legalPhaseObject.PreconditionArray.length !== 0 && (
            <>
              <br />
              <Typography variant='caption'>Vorgänger:</Typography>{' '}
            </>
          )}
          {
            <Typography variant='caption'>
              {legalPhaseObject.PreconditionArray.map(x => x.LegalPhase).join(
                ', '
              )}
            </Typography>
          }
        </Alert>
      </Grid>

      {legalReqArray.map(legalRequirement => (
        <LegalRequirementRow
          key={`idLegalRequirement-${legalRequirement.idLegalRequirement}`}
          configObject={props.configObject}
          legalTransaction={props.legalTransaction}
          legalRequirement={legalRequirement}
          legalRequirementArray={legalReqArray}
          setLegalRequirementArray={setLegalReqArray}
          isNotDisabled
          startDeadlineDate={
            props.legalTransaction.StartEditDate ||
            props.legalTransaction.NotarizationDate ||
            null
          }
        />
      ))}
    </>
  );
};
