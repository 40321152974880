import React, { useState } from 'react';
import { Dialog, IconButton, Typography } from '@mui/material';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { LegalRequirementRequestFiles } from '../LegalPhase/LegalRequirementRequestFiles';
import { IDocument } from '../../../../Interfaces/IDocument';
import { ILegalTransactionFullObject } from '../../../../Interfaces/ILegalTransaction';
import { Download } from '@mui/icons-material';
import { IConfig } from '../../../../Interfaces/IConfig';

interface IProps {
  configObject: IConfig;
  legalTransactionObject: ILegalTransactionFullObject;
  draftArray: IDocument[];
}

export const DraftDocumentOverview: React.FC<IProps> = ({
  configObject,
  legalTransactionObject,
  draftArray,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [idDocument, setIdDocument] = useState<number | undefined>(undefined);

  const handleDownloadReques = (localIdDocument: number) => {
    setIdDocument(localIdDocument);
    setIsOpen(true);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        maxWidth='lg'
        fullWidth
      >
        <LegalRequirementRequestFiles
          configObject={configObject}
          idDocument={idDocument}
          legalTransaction={legalTransactionObject}
          documentArray={draftArray.filter(document => document.idDocument > 0)}
          setIsOpen={setIsOpen}
        />
      </Dialog>

      <Typography variant='h5'>Entwürfe</Typography>

      {draftArray.length === 0 && <>--- Keine Entwürfe vorhanden ---</>}

      <Timeline>
        {draftArray.map(document => (
          <>
            <TimelineItem>
              <TimelineOppositeContent>
                {new Date(String(document.Created_at)).toLocaleString()}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent color='text.secondary' sx={{ mb: 3 }}>
                {document.FileName}
                <br />
                <IconButton
                  size='small'
                  onClick={() => handleDownloadReques(document.idDocument)}
                >
                  <Download />
                </IconButton>
              </TimelineContent>
            </TimelineItem>
          </>
        ))}
      </Timeline>
    </>
  );
};
